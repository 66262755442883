import { Theme } from '@mui/material/styles';
import * as Schema from 'generated/graphql/schema';
import * as i18next from 'i18next';

import { stopTypeNames } from './stop-type-names';

interface LineStatusInfo {
  statusColor: string;
  statusText: string;
  lineDuration: number;
  unresolvedCall?: Schema.Urgency;
}
const translateStoptypename = (t: i18next.TFunction, lineCause: string): string => {
  if (stopTypeNames(t)[lineCause as keyof typeof stopTypeNames]) {
    return stopTypeNames(t)[lineCause as keyof typeof stopTypeNames];
  } else {
    return lineCause;
  }
};

const getLineStatusInfo = (
  t: i18next.TFunction,
  theme: Theme,
  lineStatus: string | undefined | null,
  lineDuration: number,
  stopRegisterThresholdInMillis: number,
  lineCause: string,
  unresolvedCall?: Schema.Urgency,
): LineStatusInfo => {
  switch (lineStatus) {
    case 'OFF':
      return {
        statusColor: '#8e24aa',
        statusText: t(['batches:statusOffline'], { defaultValue: 'Offline' }),
        lineDuration,
        unresolvedCall,
      };
    case 'DOWNTIME':
      if (lineDuration >= stopRegisterThresholdInMillis) {
        return {
          statusColor: '#d32f2f',
          statusText: translateStoptypename(t, lineCause),
          lineDuration,
          unresolvedCall,
        };
      } else {
        return {
          statusColor: '#43a047',
          statusText: t(['batches:statusPending'], { defaultValue: 'Pending' }),
          lineDuration,
          unresolvedCall,
        };
      }
    case 'NO_ACT':
      return {
        statusColor: theme.blackbird.stops.NO_ACT.color.main,
        statusText: lineCause,
        lineDuration,
        unresolvedCall,
      };
    case 'NON_PROD':
      return { statusColor: '#62757f', statusText: lineCause, lineDuration, unresolvedCall };
    case 'BATCH_NON_PROD':
      return {
        statusColor: theme.blackbird.stops.BATCH_NON_PROD.color.main,
        statusText: lineCause,
        lineDuration,
        unresolvedCall,
      };
    case 'RUNNING':
      return {
        statusColor: '#43a047',
        statusText: t(['batches:statusRunning'], { defaultValue: 'Running' }),
        lineDuration,
        unresolvedCall,
      };
    default:
      return {
        statusColor: '#fff',
        statusText: '',
        lineDuration: 0,
        unresolvedCall,
      };
  }
};

export default getLineStatusInfo;
